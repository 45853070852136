import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  accountInfo: {},
  defaultCompany: "",
  firstName: "",
  lastName: "",
  currentCompany: {},
  defaultImg:
    "https://static.vecteezy.com/system/resources/previews/008/442/086/original/illustration-of-human-icon-user-symbol-icon-modern-design-on-blank-background-free-vector.jpg",
  // avatar: "",
  isLoggedIn: false,
  paymentsData: [],
  bookingsData: [],
  menuIsOpen: false,
  filteredData: [],
  selectedFilter: "all",
};

export const accountDataSlice = createSlice({
  name: "accountData",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setSelectedFilter: (state, action) => {
      state.selectedFilter = action.payload;
    },
    setAccountInfo: (state, action) => {
      state.accountInfo = action.payload;
    },
    setDefaultCompany: (state, action) => {
      state.defaultCompany = action.payload;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setCurrentCompany: (state, action) => {
      state.currentCompany = action.payload;
    },
    setDefaultImg: (state, action) => {
      state.defaultImg = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setAvatar: (state, action) => {
      state.avatar = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setPaymentsData: (state, action) => {
      state.paymentsData = action.payload;
    },
    setBookingsData: (state, action) => {
      state.bookingsData = action.payload;
    },
    setMenuIsOpen: (state, action) => {
      state.menuIsOpen = action.payload;
    },
    setFilteredData: (state, action) => {
      state.filteredData = action.payload;
    },
  },
});

export const {
  setToken,
  setCompanies,
  setAccountInfo,
  setDefaultCompany,
  setFirstName,
  setCurrentCompany,
  setDefaultImg,
  setLastName,
  setAvatar,
  setIsLoggedIn,
  setPaymentsData,
  setBookingsData,
  setMenuIsOpen,
  setFilteredData,
  setSelectedFilter,
} = accountDataSlice.actions;

export default accountDataSlice.reducer;
