import React from "react";
import { useState, useEffect } from "react";
import axios from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setToken } from "../../store/accountDataSlice";

function UpdateProfileForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [defCompanyId, setDefCompanyId] = useState();
  const [options, setOptions] = useState([]);
  const [message, setMessage] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [userDefaultCompany, setUserDefaultCompany] = useState("");

  const navigate = useNavigate();

  const userInfo = JSON.parse(sessionStorage.getItem("accountInfo"));
  const userFirstName = userInfo.first_name;
  const userLastName = userInfo.last_name;
  const companies = userInfo.company_ids;

  const dispatch = useDispatch();

  useEffect(() => {
    const id = userInfo.default_company_id;
    companies.find((item) => {
      if (id === item.pk) {
        setUserDefaultCompany(item.pk);
      }
    });
  }, [companies]);

  useEffect(() => {
    const getCompanyList = async () => {
      let companies = [];
      await axios
        .get(`/account/my/`)
        .then((data) => {
          data.data.company_ids.map((item) => {
            return companies.push({
              value: item.pk,
              label: item.name,
            });
          });
          setOptions(companies);
        })
        .catch((error) => {
          error && logoutBtn();
        });
    };
    getCompanyList();
  }, []);

  const logoutBtn = () => {
    sessionStorage.clear();
    dispatch(setToken(null));
    setToken(null);
    navigate("/login");
  };

  const updateProfile = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("first_name", firstName.length ? firstName : userFirstName);
    formData.append("last_name", lastName.length ? lastName : userLastName);
    formData.append(
      "default_company_id",
      defCompanyId ? defCompanyId : userDefaultCompany
    );

    axios
      .put("account/update/", formData, {
        timeout: 5000,
        headers: {
          "Content-Type": "multipart/form-data",
          accept: "application/json",
        },
      })
      .then((response) => {
        if (response) {
          setMessage("Vos modifications ont été enregistrées.");
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMsg("Veuillez choisir une société par défaut.");
        if (err.response.data.code === "token_not_valid") {
          logoutBtn();
        } else {
        }
      });
  };

  const handleChangeSelect = (e, _) => {
    setDefCompanyId(e.target.value);
  };

  const updateFirstName = (e) => {
    setFirstName(e.target.value);
  };

  return (
    <div className="text-gray-800">
      <h1 className="text-center md:text-[20px] md:text-left md:ml-5 text-gray-700 text-lg md:text-lg font-semibold md:pt-[28px] md:pb-[21px] max-md:p-4">
        Mon compte
      </h1>
      <form
        method="put"
        onSubmit={updateProfile}
        className="flex flex-col mx-5 md:mx-auto  p-4 bg-white shadow-md md:w-[700px]"
      >
        <div>
          <label className="text-sm font-semibold" htmlFor="email">
            Prènom
          </label>
          <input
            className="w-full mt-1 px-3 py-2 mb-5 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
            focus:outline-none focus:border-violet-500 focus:ring-1 focus:ring-violet-500
            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none flex gap-2"
            type="text"
            name="firstName"
            id="firstName"
            onChange={updateFirstName}
            defaultValue={userFirstName}
            minLength="2"
            maxLength="20"
          />
        </div>
        <div>
          <label className="text-sm font-semibold" htmlFor="lastName">
            Nom de Famille
          </label>
          <input
            className="mt-1 block w-full px-3 py-2 mb-7 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
            focus:outline-none focus:border-violet-500 focus:ring-1 focus:ring-violet-500
            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
            type="text"
            name="lastName"
            id="lastName"
            onChange={(e) => setLastName(e.target.value)}
            defaultValue={userLastName}
            minLength="2"
            maxLength="20"
          />
        </div>
        <div className="mb-5">
          <label className="text-sm font-semibold">Société par défaut: </label>
          <select
            onChange={handleChangeSelect}
            defaultValue={userDefaultCompany}
            className="bg-white text-gray-800 font-medium cursor-pointer text-xs md:text-base px-2 py-1 hover:text-violet-600 border rounded"
          >
            {options.map((option, i) => {
              return (
                <option value={option.value} key={i}>
                  {option.label}
                </option>
              );
            })}
          </select>
        </div>

        <div className="flex flex-col">
          <input
            value="Modifier"
            type="submit"
            className="my-4 py-2 px-4 bg-violet-600 text-white font-semibold rounded-lg shadow-md hover:bg-violet-700 focus:outline-none focus:ring-2 cursor-pointer"
          />
        </div>
        {message && !errorMsg ? (
          <p className="text-center text-green-600 font-semibold">{message}</p>
        ) : null}
        {errorMsg && !message ? (
          <p className="text-center text-red-600 font-semibold">{errorMsg}</p>
        ) : null}
      </form>
    </div>
  );
}

export default UpdateProfileForm;
