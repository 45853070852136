import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Spinner from "../../components/Spinner/Spinner";
import PaymentPageContent from "./PaymentPageContent/PaymentPageContent";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

function PaymentPage() {
  const [paymentData, setPaymentData] = useState();
  const [languages, setLanguages] = useState([]);
  const [defaultLang, setDefaultLang] = useState("");
  const [defaulContent, setDefaultContent] = useState({});
  const [checkedBtn, setCheckedBtn] = useState(false);
  const [validCard, setValidCard] = useState(false);
  const [message, setMessage] = useState("");
  const [fail, setFail] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  let { uuid } = useParams();
  let pk = "%REACT_APP_PAYGREEN_PK_KEY%";
  const apiKey = "%REACT_APP_MPAY_API_KEY%";
  const baseUrl = "%REACT_APP_MPAY_API_URL%";

  const dataFetch = useRef(false);
  const { t } = useTranslation();

  // ================ styles for pg container ==================
  const style = {
    input: {
      base: {
        color: "black",
        fontSize: "16px",
      },
      hover: {
        color: "grey",
      },
      focus: {
        color: "grey",
      },
      invalid: {
        color: "red",
      },
      placeholder: {
        base: {
          color: "grey",
          fontSize: "16px",
        },
      },
    },
    checkbox: {
      label: {
        unchecked: {
          color: "red",
        },
        base: {
          color: "black",
        },
      },
      box: {
        base: {
          color: "#002B5B",
          hover: {
            color: "#424242",
          },
        },
        unchecked: {
          color: "#002B5B",
        },
      },
    },
  };

  // ======================== axios config ========================

  axios.defaults.headers.common["Authorization"] = `Api-Key ${apiKey}`;
  axios.defaults.baseURL = baseUrl;

  // ========================= paygreen init ======================

  useEffect(() => {
    if (dataFetch.current) return;
    dataFetch.current = true;
    fetchData();
  }, [dataFetch]);

  useEffect(() => {
    if (paymentData) {
      return pgInit();
    }
  }, [paymentData]);

  const fetchData = () => {
    axios
      .get(`payment_order/${uuid}/init_payment/`)
      .then(function (data) {
        setPaymentData(data.data);
        setDefaultLang(data.data.reservation.language);

        for (const [key, value] of Object.entries(data.data.company_content)) {
          languages.push({ value: key, label: value });
        }
      })
      .catch(function (error) {
        if (
          error.response.data.error == "Payment already authorized or captured"
        ) {
          setSuccess(true);
        } else {
          setErrorMsg("Réservation introuvable");
          console.log(error);
        }
      });
  };

  const pgInit = () => {
    if (paymentData.instrument === true) {
      initInstrument();
    } else if (
      paymentData.instrument === false &&
      paymentData.po_type == "Fingerprint"
    ) {
      initFingerPrint();
    } else {
      initPayment();
    }
  };

  //=================================== init functions =========================================

  const initInstrument = () => {
    window.paygreenjs.init({
      paymentOrderID: paymentData.po_id,
      objectSecret: paymentData.object_secret,
      publicKey: pk,
      buyer: paymentData.buyer,
      modeOptions: {
        authorizedInstrument: true,
      },
      paymentMethod: "bank_card",
      mode: "instrument",
      lang: defaultLang,
      style,
    });

    checkBtnHandler();

    window.paygreenjs.attachEventListener(
      window.paygreenjs.Events.INSTRUMENT_READY,
      (event) => {
        const instrument = event.detail.instrument;

        if (instrument?.authorized === true) {
          axios
            .put(`/payment_order/${uuid}/add_instrument/`, {
              instrument_id: instrument.id,
            })
            .then((data) => {
              setMessage(t("save_card_msg"));
            })
            .catch(function (error) {
              if (error.response) {
                console.log(error.response.data.error);
                setMessage("problem with card details, cannot save");
                setFail(true);
              } else {
                setFail(true);
                setMessage(t("saving_fail_msg"));
              }
            });
        } else {
          setFail(true);
          setMessage(t("saving_fail_msg"));
        }
      }
    );
  };

  const initFingerPrint = () => {
    window.paygreenjs.init({
      paymentOrderID: paymentData.po_id,
      objectSecret: paymentData.object_secret,
      publicKey: pk,
      auto_capture: false,
      paymentMethod: "bank_card",
      mode: "payment",
      lang: defaultLang,
      style,
    });

    checkBtnHandler();
  };

  const initPayment = () => {
    window.paygreenjs.init({
      paymentOrderID: paymentData.po_id,
      objectSecret: paymentData.object_secret,
      publicKey: pk,
      paymentMethod: "bank_card",
      mode: "payment",
      lang: defaultLang,
      style,
    });

    window.paygreenjs.attachEventListener(
      window.paygreenjs.Events.CARD_ONCHANGE,
      (event) => {
        if (event.detail.valid === false) {
          document.getElementById("payBtn").disabled = true;
        } else {
          document.getElementById("payBtn").disabled = false;
        }
      }
    );
  };

  // =============================== paygreen event listeners ==================================

  const checkBtnHandler = () => {
    window.paygreenjs.attachEventListener(
      window.paygreenjs.Events.REUSABLE_ALLOWED_CHANGE,
      (event) => {
        if (event.detail.reusable_allowed === true) {
          setCheckedBtn(true);
        } else {
          setCheckedBtn(false);
        }
      }
    );

    window.paygreenjs.attachEventListener(
      window.paygreenjs.Events.CARD_ONCHANGE,
      (event) => {
        if (event.detail.valid === true) {
          setValidCard(true);
        } else {
          setValidCard(false);
        }
      }
    );
  };

  window.paygreenjs.attachEventListener(
    window.paygreenjs.Events.PAYMENT_FAIL,
    (event) => {
      setFail(true);
      setMessage(t("payment_fail_msg"));
    }
  );

  window.paygreenjs.attachEventListener(
    window.paygreenjs.Events.FULL_PAYMENT_DONE,

    (event) => {
      if (paymentData.instrument || paymentData.po_type === "Fingerprint") {
        setMessage(t("save_card_msg"));
      } else {
        setMessage(t("payment_success_msg"));
      }
    }
  );

  window.paygreenjs.attachEventListener(
    window.paygreenjs.Events.TOKEN_FAIL,
    (event) => {
      console.error("Fail to tokenize card: ", event?.detail);
      setFail(true);
      setMessage(t("wrong_card_msg"));
    }
  );

  window.paygreenjs.attachEventListener(
    window.paygreenjs.Events.ERROR,
    (event) => {
      console.error("Your custom error handling", event?.detail);
      setMessage("Internal Server Error");
      setFail(true);
    }
  );

  window.paygreenjs.attachEventListener(
    window.paygreenjs.Events.PAN_FIELD_FULFILLED,
    () => {
      window.paygreenjs.focus("exp");
    }
  );
  window.paygreenjs.attachEventListener(
    window.paygreenjs.Events.EXP_FIELD_FULFILLED,
    () => {
      window.paygreenjs.focus("cvv");
    }
  );

  // ==================================== error page ============================================

  const getErrorView = () => {
    return (
      <div
        className="h-screen w-full lg:h-screen bg-cover bg-center flex flex-col items-center justify-center  "
        style={{ backgroundImage: `url('${paymentData.company.cover}')` }}
      >
        <div className=" text-center backdrop-blur-xl bg-black/20 p-8 md:p-[100px] rounded-lg">
          {fail ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="fill-red-500 w-[30px] mx-auto mb-2 md:w-[50px]"
            >
              <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="fill-green-500 w-[30px] mx-auto mb-2 md:w-[50px]"
            >
              <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
            </svg>
          )}

          <h1 className="md:text-[24px] text-white mb-6">{message}</h1>

          {fail ? (
            <button
              className="text-white text-sm bg-[#2d916b] hover:bg-[#185e43] rounded-md px-2 py-1.5 cursor-pointer md:text-lg"
              onClick={() => window.location.reload()}
            >
              {t("try_again_btn")}
            </button>
          ) : null}
        </div>
      </div>
    );
  };

  const getSuccessView = () => {
    // document.getElementById("paygreen-container").style.display = "none";
    return (
      <div className="bg-gray-50 flex flex-col items-center justify-center h-screen">
        {errorMsg ? (
          <div className="flex flex-col items-center p-10 gap-1 bg-white text-[#343435] text-sm rounded shadow-xl md:text-lg">
            <FontAwesomeIcon
              icon={faCircleXmark}
              style={{ color: "red" }}
              size="3x"
              className="mb-4"
            />
            <p>{errorMsg}</p>
          </div>
        ) : (
          <div className="flex flex-col items-center p-10 gap-1 bg-white text-[#343435] text-sm rounded shadow-xl md:text-lg">
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ color: "#4cbf4a" }}
              size="3x"
              className="mb-4"
            />
            <h1>Paiement déjà autorisé ou saisi.</h1>
            <h1>Payment already authorized or captured.</h1>
            <h1>Bereits autorisierte oder erfasste Zahlung.</h1>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {message ? getErrorView() : null}

      {success || errorMsg ? getSuccessView() : null}

      {paymentData ? (
        <PaymentPageContent
          paymentData={paymentData}
          languages={languages}
          defaultLang={defaultLang}
          defaulContent={defaulContent}
          setDefaultContent={setDefaultContent}
          checkedBtn={checkedBtn}
          validCard={validCard}
        />
      ) : null}
      {!paymentData && !message && !success && !errorMsg ? <Spinner /> : null}
    </>
  );
}

export default PaymentPage;
