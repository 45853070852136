import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../../store/accountDataSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import useOutsideClick from "../../hooks/useOutsideClick";
import {
  faGear,
  faRightFromBracket,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

function ProfileDropdown() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useOutsideClick(false);
  const defaultImg = useSelector((state) => state.accountData.defaultImg);

  const toggleProfileMenu = () => {
    return setIsComponentVisible((prevState) => !prevState);
  };

  const logoutBtn = () => {
    sessionStorage.clear();
    dispatch(setToken(null));
    setToken(null);
    navigate("/login");
  };

  const logoutAlert = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="bg-slate-700 p-10 rounded-md text-white flex flex-col gap-6">
            <h1 className="text-lg">Voulez-vous vous déconnecter?</h1>
            <div className="flex flex-row items-center justify-center gap-4 ">
              <button
                onClick={onClose}
                className="border-2 border-white rounded-md px-2 py-1 hover:bg-white hover:text-gray-700"
              >
                Non
              </button>
              <button
                onClick={() => {
                  logoutBtn();
                  onClose();
                }}
                className="border-2 border-transparent bg-white rounded-md px-2 py-1 text-gray-700 hover:text-red-500 font-semibold"
              >
                Oui
              </button>
            </div>
          </div>
        );
      },
    });
  };

  return (
    <div className="relative text-left ">
      <div
        className="flex justify-center items-center border-transparent rounded-lg bg-slate-100 p-2 md:max-lg:p-0 md:max-lg:bg-inherit"
        ref={ref}
      >
        <button
          onClick={toggleProfileMenu}
          className="flex flex-row items-center gap-[14px] hover:text-violet-800"
        >
          <img
            className="w-[30px] h-[30px] md:w-[40px] md:h-[40px] rounded-full shadow-md "
            src={defaultImg}
            alt="user"
          />

          <span className="text-sm max-lg:hidden">Mon compte</span>
          <FontAwesomeIcon
            icon={faAngleRight}
            className={`max-lg:hidden  ${
              isComponentVisible ? "-rotate-90" : ""
            }`}
          />
        </button>
      </div>
      <div
        className={`absolute right-0 z-20 w-56 origin-top-right rounded-md bg-[#23273a]  shadow-lg focus:outline-none px-2 md:top-[-85px] md:left-0 lg:left-16  ${
          isComponentVisible ? "block" : "hidden"
        }`}
      >
        <div className="py-1">
          <Link
            to="dashboard/account"
            className="text-white block px-4 py-2 text-sm hover:bg-slate-700 rounded-md "
          >
            <FontAwesomeIcon icon={faGear} className="pr-2" />
            <span>Paramètres de profil</span>
          </Link>

          <Link
            className="text-white hover:bg-slate-700 block w-full px-4 py-2 text-left text-sm rounded-md"
            onClick={logoutAlert}
          >
            <FontAwesomeIcon icon={faRightFromBracket} className="pr-2" />
            <span>Déconnexion</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ProfileDropdown;
