import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";
import axios from "../../services/api";
import { useSelector } from "react-redux";
import Button from "../Button/Button";
import { useEffect } from "react";

const TableBody = ({ tableData, columns }) => {
  const currentCompany = useSelector(
    (state) => state.accountData.currentCompany
  );
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  return (
    <tbody>
      {tableData.map((item) => {
        return (
          <tr key={item.uuid} className=" text-sm">
            {columns.map(({ accessor, format }) => {
              const titem = item[accessor] ? item[accessor] : "——";

              if (accessor === "dashboardBtn") {
                return (
                  <td key={accessor} className="border-b">
                    <Link to={`/dashboard/bookings/${item.reservation_id}`}>
                      <Button type={"details"}>
                        Détails
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          style={{ opacity: 0.75 }}
                        />
                      </Button>
                    </Link>
                  </td>
                );
              } else if (accessor === "bookingsBtn") {
                return (
                  <td key={accessor} className="pr-2 border-b">
                    <Link to={`/dashboard/bookings/${item.uuid}`}>
                      <Button type={"details"}>
                        Détails
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          style={{ opacity: 0.75 }}
                        />
                      </Button>
                    </Link>
                  </td>
                );
              } else if (
                accessor === "captureBtn" &&
                item.is_capturable === true
              ) {
                return (
                  <td
                    key={accessor}
                    className="pr-2 border-b text-center max-sm:hidden"
                  >
                    <button
                      onClick={() => {
                        confirmAlert({
                          customUI: ({ onClose }) => {
                            return (
                              <div className="bg-slate-700 p-10 rounded-md text-white flex flex-col gap-6">
                                <h1 className="text-lg">
                                  Voulez-vous capturer le paiement?
                                </h1>
                                <div className="flex flex-row items-center justify-center gap-4 ">
                                  <button
                                    onClick={onClose}
                                    className="border-2 border-white rounded-md px-2 py-1 hover:bg-white hover:text-gray-700"
                                  >
                                    Non
                                  </button>
                                  <button
                                    onClick={() => {
                                      axios
                                        .post(
                                          `payment/${currentCompany}/${item.uuid}/capture/`
                                        )
                                        .then()
                                        .catch((error) => {});
                                      onClose();
                                    }}
                                    className="border-2 border-transparent text-white bg-[#2d916b] hover:bg-[#185e43] font-semibold rounded-md  px-2 py-1 cursor-pointer"
                                  >
                                    Oui
                                  </button>
                                </div>
                              </div>
                            );
                          },
                        });
                      }}
                      className={`text-white bg-[#2d916b] hover:bg-[#185e43] rounded-lg text-sm px-2 py-1 cursor-pointer ${
                        item.check_in ? "bg-slate-200" : ""
                      }`}
                    >
                      Capturer
                    </button>
                  </td>
                );
              } else if (
                item.status === "payment_order.successed" &&
                accessor === "captureBtn"
              ) {
                return (
                  <td
                    key={accessor}
                    className="p-3 text-gray-700 border-b text-center max-sm:hidden"
                  >
                    <button
                      onClick={() => {
                        confirmAlert({
                          customUI: ({ onClose }) => {
                            return (
                              <div className="bg-slate-700 p-10 rounded-md text-white flex flex-col gap-6">
                                <h1 className="text-lg">
                                  Voulez-vous rembourser le paiement?
                                </h1>
                                {message && <p>{message}</p>}
                                <div className="flex flex-row items-center justify-center gap-4 ">
                                  <button
                                    onClick={onClose}
                                    className="border-2 border-white rounded-md px-2 py-1 hover:bg-white hover:text-gray-700"
                                  >
                                    Non
                                  </button>
                                  <button
                                    onClick={() => {
                                      axios
                                        .post(
                                          `payment/${currentCompany}/${item.uuid}/refund/`,
                                          {
                                            transaction_amount: parseInt(
                                              item.amount * 100
                                            ),
                                            transaction_operation_id:
                                              item.payment_order_id,
                                          }
                                        )
                                        .then((response) => {
                                          window.location.reload();
                                        })
                                        .catch((error) => {
                                          if (error) {
                                            setMessage(error.error);
                                          }
                                        });
                                      onClose();
                                    }}
                                    className="border-2 border-transparent text-white bg-[#2d916b] hover:bg-[#185e43] font-semibold rounded-md  px-2 py-1 cursor-pointer"
                                  >
                                    Oui
                                  </button>
                                </div>
                              </div>
                            );
                          },
                        });
                      }}
                      className=" bg-violet-400 px-2 py-1 rounded-md cursor-pointer hover:bg-violet-500  text-white "
                    >
                      Rembourser
                    </button>
                  </td>
                );
              } else if (accessor === "paymentBtn") {
                return (
                  <td key={accessor} className="pr-2 border-b">
                    <Link to={`/dashboard/payments/${item.uuid}`}>
                      <Button type={"details"}>
                        Détails
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          style={{ opacity: 0.75 }}
                        />
                      </Button>
                    </Link>
                  </td>
                );
              } else if (format === "date") {
                return (
                  <td
                    key={accessor}
                    className="p-3 text-gray-700 border-b text-center max-sm:hidden"
                  >
                    {new Date(titem)
                      .toLocaleDateString("fr")
                      .replace(/\//g, "-")}
                  </td>
                );
              } else if (format === "currency") {
                return (
                  <td
                    key={accessor}
                    className="p-3 text-gray-700 border-b text-center max-sm:hidden"
                  >
                    {`${titem.toFixed(2)} €`}
                  </td>
                );
              } else if (
                accessor === "first_name" ||
                accessor === "last_name"
              ) {
                return (
                  <td
                    key={accessor}
                    className="p-3 md:pl-2 text-left font-semibold text-gray-700 border-b"
                  >
                    {titem}
                  </td>
                );
              } else if (
                item.payment_type === "payment" &&
                accessor === "payment_type"
              ) {
                return (
                  <td
                    key={accessor}
                    className="p-3 text-gray-700 border-b text-center max-sm:hidden"
                  >
                    Paiement
                  </td>
                );
              } else if (
                item.payment_type === "fingerprint" &&
                accessor === "payment_type"
              ) {
                return (
                  <td
                    key={accessor}
                    className="p-3 text-gray-700 border-b text-center max-sm:hidden"
                  >
                    Empreinte
                  </td>
                );
              } else if (
                item.status === "payment_order.pending" &&
                accessor === "status"
              ) {
                return (
                  <td
                    key={accessor}
                    className="p-3 text-gray-700 border-b text-center max-sm:hidden"
                  >
                    <span className="text-orange-500 px-2 py-1 rounded-md">
                      En attente
                    </span>
                  </td>
                );
              } else if (
                item.status === "payment_order.authorized" &&
                accessor === "status"
              ) {
                return (
                  <td
                    key={accessor}
                    className="p-3 text-gray-700 border-b text-center max-sm:hidden"
                  >
                    <span className="text-blue-500 px-2 py-1 rounded-md">
                      Autorisé
                    </span>
                  </td>
                );
              } else if (
                item.status === "payment_order.successed" &&
                item.as_captured === false &&
                accessor === "status"
              ) {
                return (
                  <td
                    key={accessor}
                    className="p-3 text-gray-700 border-b text-center max-sm:hidden"
                  >
                    <span className="text-green-500 px-2 py-1 rounded-md">
                      Payé
                    </span>
                  </td>
                );
              } else if (
                item.status === "payment_order.successed" &&
                item.as_captured === true &&
                accessor === "status"
              ) {
                return (
                  <td
                    key={accessor}
                    className="p-3 text-gray-700 border-b text-center max-sm:hidden"
                  >
                    <span className="text-green-500 px-2 py-1 rounded-md">
                      Payé/Capturé
                    </span>
                  </td>
                );
              } else if (
                item.status === "payment_order.refused" &&
                accessor === "status"
              ) {
                return (
                  <td
                    key={accessor}
                    className="p-3 text-gray-700 border-b text-center max-sm:hidden"
                  >
                    <span className="text-red-500 px-2 py-1 rounded-md">
                      Refusé
                    </span>
                  </td>
                );
              } else if (
                item.status === "not_initiated" &&
                accessor === "status"
              ) {
                return (
                  <td
                    key={accessor}
                    className="p-3 text-gray-700 border-b text-center max-sm:hidden"
                  >
                    <span className="text-yellow-500 px-2 py-1 rounded-md">
                      Pas initialisé
                    </span>
                  </td>
                );
              } else if (
                (item.status === "payment_order.refunded" &&
                  accessor === "status") ||
                (accessor === "status" && status === "payment_order.refunded")
              ) {
                return (
                  <td
                    key={accessor}
                    className="p-3 text-gray-700 border-b text-center max-sm:hidden"
                  >
                    <span className="text-yellow-700 px-2 py-1 rounded-md">
                      Remboursé
                    </span>
                  </td>
                );
              } else {
                return (
                  <td
                    key={accessor}
                    className="p-3 text-gray-700 border-b text-center max-sm:hidden"
                  >
                    {titem}
                  </td>
                );
              }
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
