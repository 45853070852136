import React from "react";
import { useTranslation } from "react-i18next";

function BookingInfo({ paymentData, selectedLang }) {
  const { t } = useTranslation();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  let checkInDate = new Date(paymentData.reservation.check_in)
    .toLocaleDateString(selectedLang, options)
    .toString();

  checkInDate = checkInDate.charAt(0).toUpperCase() + checkInDate.slice(1);

  let checkOutDate = new Date(paymentData.reservation.check_out)
    .toLocaleDateString(selectedLang, options)
    .toString();
  checkOutDate = checkOutDate.charAt(0).toUpperCase() + checkOutDate.slice(1);

  const hours = (
    "0" + new Date(paymentData.reservation.check_in).getHours()
  ).substr(-2);
  const minutes = (
    "0" + new Date(paymentData.reservation.check_in).getMinutes()
  ).substr(-2);

  return (
    <div>
      <h1 className="text-center font-semibold text-lg text-[#343435] leading-4 mb-10">
        {t("title")}
      </h1>
      <div className="pb-10 text-[#343435]">
        <div>
          <div>
            <span className="italic">{t("last_name")}: </span>
            <span className="font-semibold uppercase">
              {paymentData.reservation.last_name}
            </span>
          </div>
          <div>
            <span className="italic">{t("first_name")}: </span>
            <span className="font-semibold">
              {paymentData.reservation.first_name}
            </span>
          </div>
        </div>

        <div>
          <span className="italic">{t("adults")}: </span>
          <span className="font-semibold">
            {paymentData.reservation.adults}
          </span>
        </div>
        <div>
          <span className="italic">{t("children")}: </span>
          <span className="font-semibold">
            {paymentData.reservation.children}
          </span>
        </div>
        {paymentData.reservation.reservation_type === "hotel" ? (
          <>
            <div>
              <span className="italic">{t("check_in")}: </span>
              <span className="font-semibold">{checkInDate}</span>
            </div>
            <div>
              <span className="italic">{t("check_out")}: </span>
              <span className="font-semibold">{checkOutDate}</span>
            </div>
          </>
        ) : (
          <>
            <div>
              <span className="italic">{t("date")}: </span>
              <span className="font-semibold">{checkInDate}</span>
            </div>
            <div>
              <span className="italic">{t("hour")}: </span>
              <span className="font-semibold">{`${hours}h${minutes}`}</span>
            </div>
          </>
        )}

        <div className="col-span-2 md:mt-2">
          <span className="italic">{t("email")}: </span>
          <span className="font-semibold">{paymentData.reservation.email}</span>
        </div>
        <div className="col-span-2">
          <span className="italic">{t("reference")}: </span>
          <span className="font-semibold">
            {paymentData.reservation.reference}
          </span>
        </div>

        {paymentData.instrument || paymentData.po_type === "Fingerprint" ? (
          <div className="col-span-2">
            <span className="italic">{t("deposit_amount")}: </span>
            <span className="font-semibold">
              {paymentData.amount.toFixed(2) + "€"}
            </span>
            <div className="col-span-2">
              <span className="text-[#ff0000] text-[12px] leading-none">
                {t("noshow_msg")}
              </span>
            </div>
          </div>
        ) : (
          <div>
            <span className="italic">{t("amount")}: </span>
            <span className="font-semibold">
              {paymentData.amount.toFixed(2) + "€"}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default BookingInfo;
