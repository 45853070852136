import React, { useEffect, useState } from "react";
import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SearchBar({ data, setFilteredData }) {
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const result = data.filter((el) => {
      if (searchInput === "") {
        return el;
      } else {
        const fullName = `${el.last_name} ${el.first_name}`;
        return fullName.toLowerCase().includes(searchInput);
      }
    });
    setFilteredData(result);
    if(searchInput === "") {
      sessionStorage.setItem("searching", searchInput);
    }
  }, [searchInput, data]);

  let inputHandler = (e) => {
    e.preventDefault();
    var lowerCase = e.target.value.toLowerCase();
    sessionStorage.setItem("searching", e.target.value);
    setSearchInput(lowerCase);
  };

  return (
    <div className="max-md:hidden flex items-center pl-2 pb-4 ">
      <FontAwesomeIcon
        icon={faMagnifyingGlass}
        className="text-sm text-gray-400 relative mr-[-22px]"
      />
      <input
        type="text"
        placeholder="Rechercher par nom"
        value={searchInput}
        className="hover:border-gray-300 rounded-lg border-[1px] p-1 pl-7 placeholder:text-sm  text-gray-800 focus:border-violet-900  "
        onChange={inputHandler}
      />
      <button
        onClick={() => setSearchInput("")}
        className="relative text-gray-500 hover:text-red-500 ml-[-20px]"
      >
        <FontAwesomeIcon icon={faXmark} size="sm" />
      </button>
    </div>
  );
}

export default SearchBar;
