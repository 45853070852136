import React, { useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import Table from "../../components/Table/Table";
import SearchBar from "../../components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../services/api";
import FilterDropdown from "../../components/FilterDropdown/FilterDropdown";

function PaymentOrders() {
  const columns = [
    { label: "Nom", accessor: "last_name" },
    { label: "Prénom", accessor: "first_name" },
    { label: "Date de création", accessor: "created_at", format: "date" },
    { label: "Type", accessor: "payment_type" },
    { label: "Montant", accessor: "amount", format: "currency" },
    { label: "Status", accessor: "status" },
    { label: "", accessor: "captureBtn" },
    { label: "", accessor: "paymentBtn" },
  ];

  const [filteredData, setFilteredData] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [payments, setPayments] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [sortedPayments, setSortedPayments] = useState([]);

  const currentCompany = useSelector(
    (state) => state.accountData.currentCompany
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentCompany) {
      getBookings();
      getPayments();
    }
  }, [currentCompany]);

  useEffect(() => {
    let data = [];
    if (payments.length > 0 && bookings.length > 0) {
      payments.forEach((item) => {
        bookings.find((p) => {
          if (p.uuid === item.reservation_id) {
            data.push({
              ...item,
              first_name: p.first_name,
              last_name: p.last_name,
              pms_reservation_type: p.pms_reservation_type,
            });
          }
        });
      });
    }
    setCompleteData(data);
    setSortedPayments(data);
  }, [bookings, payments]);

  const getPayments = () => {
    let arr = [];

    axios
      .get(`payment/${currentCompany}/`)
      .then((data) => {
        data.data.forEach((item) => {
          arr.push(item);
        });
        setPayments(arr);
      })
      .catch((error) => {
        if (error) {
          sessionStorage.clear();
          navigate("/login");
        }
      });
  };
  const getBookings = () => {
    let arr = [];
    axios.get(`reservation/${currentCompany}/`).then((data) => {
      data.data.forEach((item) => {
        arr.push(item);
      });
      setBookings(arr);
    });
  };

  return (
    <div className="overflow-x-auto w-full px-[20px]">
      <h1 className="text-center md:text-[20px] md:text-left text-gray-700 text-lg md:text-lg font-semibold md:pt-[28px] md:pb-[21px] max-md:p-4">
        Paiements
      </h1>
      <div className="flex gap-7 ">
        <SearchBar data={sortedPayments} setFilteredData={setFilteredData} />
        <FilterDropdown data={completeData} setSortedData={setSortedPayments} />
      </div>

      <Table
        tableData={filteredData}
        columns={columns}
        pagination={true}
        caption={"Paiements"}
      />
    </div>
  );
}

export default PaymentOrders;
