import React from "react";
import Details from "../../components/Details/Details";

function PaymentOrderDetail() {
  let paymentsData = JSON.parse(sessionStorage.getItem("paymentsData"));
  let bookingsData = JSON.parse(sessionStorage.getItem("bookingsData"));

  let completeData = [];
  paymentsData.map((item) => {
    bookingsData.find((p) => {
      if (p.uuid === item.reservation_id) {
        completeData.push({
          ...item,
          first_name: p.first_name,
          last_name: p.last_name,
        });
      } else {
      }
    });
  });

  const rows = [
    { label: "Nom", accessor: "last_name" },
    { label: "Prénom", accessor: "first_name" },
    { label: "Créé le", accessor: "created_at", format: "date" },
    { label: "Status", accessor: "status" },
    { label: "Montant", accessor: "amount", format: "currency" },
    { label: "Type", accessor: "payment_type" },
    { label: "Payé le", accessor: "payment_date" },
    { label: "Capturer", accessor: "captureBtn" },
  ];

  return (
    <Details
      data={completeData}
      rows={rows}
      title={"Détails du paiement"}
      pathname={"/dashboard/payments"}
    />
  );
}

export default PaymentOrderDetail;
