import React from "react";
import NavBar from "../../components/NavBar";
import Header from "../../components/Header";
import { Outlet } from "react-router-dom";

function Layout() {
  return (
    <div>
      <Header className="md:hidden"/>
      <div className="flex flex-row">
        <NavBar className="h-screen" />
        <div className="min-h-[93vh] md:h-screen overflow-auto w-full bg-slate-50 ">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Layout;
