import React, { useEffect, useState } from "react";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import BookingInfo from "./BookingInfo";

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    lng: document.querySelector("html").lang,
    fallbackLng: "fr",
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

function PaymentPageContent({
  paymentData,
  languages,
  defaultLang,
  defaulContent,
  setDefaultContent,
  validCard,
  checkedBtn,
}) {
  const [selectedContent, setSelectedContent] = useState("");
  const [selectedLang, setSelectedLang] = useState(defaultLang);
  const { t, i18n } = useTranslation();
  const langs = [
    { name: "Français", value: "fr" },
    { name: "English", value: "en" },
    { name: "Deutch", value: "de" },
  ];

  useEffect(() => {
    languages.map((item) => {
      if (item.value === defaultLang) {
        setDefaultContent(item);
        i18n.changeLanguage(defaultLang);
        window.paygreenjs.setLanguage(defaultLang);
      }
    });
  }, [defaultLang]);

  useEffect(() => {
    document.getElementById("payBtn").disabled = true;
    if (checkedBtn && validCard) {
      document.getElementById("payBtn").disabled = false;
    }
  }, [paymentData, checkedBtn, validCard]);

  const handleLangSelect = (e) => {
    languages.map((item) => {
      if (item.value === e.target.value) {
        setSelectedContent(item.label);
        i18n.changeLanguage(item.value);
        window.paygreenjs.setLanguage(item.value);
        setSelectedLang(item.value);
      }
      return selectedContent;
    });
  };

  const handlePayBtn = () => {
    window.paygreenjs.submitPayment();
    document.getElementById("yourCustomDiv").style.display = "none";
  };

  return (
    <>
      <div>
        <div id="paygreen-container"></div>
        <div id="paygreen-methods-container"></div>

        <div
          id="yourCustomDiv"
          className="flex flex-col justify-center items-center"
        >
          <div
            style={{ backgroundImage: `url('${paymentData.company.cover}')` }}
            className="w-full min-h-screen bg-cover bg-center"
          >
            <div className="max-w-[100%] h-full md:min-h-screen flex flex-col items-center backdrop-blur-[10px] p-10 gap-10">
              <div className="self-end flex items-center gap-2 text-[#edeaea]">
                <FontAwesomeIcon icon={faGlobe} size="xl" />
                <select
                  className="appearance-none text-sm rounded-lg w-full p-1.5 font-semibold text-white text-center bg-[#343435] shadow-md hover:bg-[#29292b] cursor-pointer focus:outline-none"
                  onChange={handleLangSelect}
                  defaultValue={defaultLang}
                >
                  {langs.map((lang, i) => (
                    <option key={i} value={lang.value}>
                      {lang.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="grid rounded-lg w-full min-h-fit shadow-2xl lg:grid-cols-2 ">
                <div
                  className="bg-cover text-center rounded-t-md h-auto flex flex-col gap-6 items-center p-7 leading-6 lg:rounded-tr-none lg:rounded-bl-md text-white"
                  style={{
                    backgroundImage: `linear-gradient(180deg, #000 1%, rgba(0, 0, 2, 0)), url('${paymentData.company.cover}')`,
                  }}
                >
                  <img
                    src={paymentData.company.logo}
                    className="max-w-[150px] h-auto"
                    alt="logo"
                  />
                  {selectedContent ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: selectedContent }}
                    ></div>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{ __html: defaulContent.label }}
                    ></div>
                  )}
                </div>

                <div className="p-10 flex flex-col text-sm bg-[#f2f6fa] rounded-b-md lg:rounded-tr-md lg:rounded-bl-none">
                  <BookingInfo
                    paymentData={paymentData}
                    selectedLang={selectedLang}
                  />
                  <div>
                    <div className="mb-2 col-span-2">
                      <label>{t("card_number")}</label>
                      <div id="paygreen-pan-frame" className="bg-white"></div>
                    </div>
                    <div className="mb-2">
                      <label>{t("expiry_date")}</label>
                      <div
                        id="paygreen-exp-frame"
                        className="w-full bg-white"
                      ></div>
                    </div>
                    <div className="mb-2">
                      <label>{t("cvv")}</label>
                      <div
                        id="paygreen-cvv-frame"
                        className="w-full bg-white "
                      ></div>
                    </div>
                  </div>

                  <div
                    id="paygreen-reuse-checkbox-container"
                    className="h-[10vh]"
                  ></div>

                  {paymentData.instrument ||
                  paymentData.po_type === "Fingerprint" ? (
                    <div className="flex flex-col gap-2">
                      <span className="text-[12px] ">
                        {/* {t("check_msg")} */}
                      </span>
                      <button
                        id="payBtn"
                        type="button"
                        className=" text-white bg-[#2d916b] hover:bg-[#185e43] rounded-lg text-sm px-5 py-2.5 mt-4 disabled:bg-gray-400 disabled:cursor-default sm:text-base cursor-pointer md:w-[60%] md:self-center"
                        onClick={handlePayBtn}
                      >
                        {t("save_btn")} {""}
                      </button>
                    </div>
                  ) : (
                    <button
                      id="payBtn"
                      type="button"
                      className=" text-white bg-[#2d916b] hover:bg-[#185e43] rounded-lg text-sm px-5 py-2.5 mt-4 disabled:bg-gray-400 disabled:cursor-default sm:text-base cursor-pointer md:w-[40%] md:self-center"
                      onClick={handlePayBtn}
                    >
                      {t("pay_btn")} {""}
                    </button>
                  )}

                  <div className="flex justify-center items-center gap-2 mt-5 text-center">
                    <span className="text-sm ">
                      <FontAwesomeIcon
                        icon={faLock}
                        className="mr-1"
                        size="sm"
                        style={{ color: "#1f512e" }}
                      />
                      {t("footer_msg")}
                      <span className="font-semibold text-[#2d916b] ml-1.5">
                        PayGreen
                      </span>
                    </span>
                  </div>
                  <div className="flex justify-center items-center gap-2 mt-5 text-center">
                    <a className="text-sm" href={paymentData.company.cgv_link}>
                      Conditions générales de vente
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentPageContent;
