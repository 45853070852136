import React from "react";
import underConstruction from "../../assets/under_construction.gif";

function Help() {
  return (
    <div className="bg-[#43ACE1] w-full h-screen">
      <div
        style={{ backgroundImage: `url(${underConstruction})` }}
        className="max-w-[1300px] h-screen bg-cover bg-center mx-auto"
      >
        <p className="text-lg md:text-4xl font-semibold text-white text-center pt-10">
          Page en construction
        </p>
      </div>
    </div>
  );
}
export default Help;
