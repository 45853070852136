import React, { useEffect, useState } from "react";
import Table from "../../components/Table/Table";
import SearchBar from "../../components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../services/api";
import { useNavigate } from "react-router-dom";
import { setSelectedFilter } from "../../store/accountDataSlice";
import FilterDropdown from "../../components/FilterDropdown/FilterDropdown";

function Bookings() {
  const columns = [
    { label: "Nom", accessor: "last_name" },
    { label: "Prénom", accessor: "first_name" },
    { label: "Check-In", accessor: "checkin", format: "date" },
    { label: "Check-Out", accessor: "checkout", format: "date" },
    { label: "Adresse Mail", accessor: "email" },
    { label: "Téléphone", accessor: "phone" },
    { label: "", accessor: "bookingsBtn" },
  ];

  const dispatch = useDispatch();

  const [bookings, setBookings] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [sortedBookings, setSortedBookings] = useState([]);
  const { currentCompany, selectedFilter } = useSelector(
    (state) => state.accountData
  );

  const navigate = useNavigate();

  useEffect(() => {
    let arr = [];
    if (currentCompany) {
      axios
        .get(`reservation/${currentCompany}/`)
        .then((data) => {
          data.data.forEach((item) => {
            arr.push(item);
          });
          setBookings(arr);
          setSortedBookings(arr);
        })
        .catch((error) => {
          if (error) {
            sessionStorage.clear();
            navigate("/login");
          }
        });
    }
  }, [currentCompany]);

  return (
    <div className="overflow-x-auto w-full  px-[20px]">
      <h1 className="text-center md:text-[20px] md:text-left text-gray-700 text-lg md:text-lg font-semibold md:pt-[28px] md:pb-[21px] max-md:p-4">
        Réservations
      </h1>
      <div className="flex gap-7 ">
        <SearchBar data={sortedBookings} setFilteredData={setFilteredData} />
        <FilterDropdown data={bookings} setSortedData={setSortedBookings} />
      </div>

      <Table
        tableData={filteredData}
        columns={columns}
        pagination={true}
        caption={"Réservations"}
      />
    </div>
  );
}

export default Bookings;
