import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../services/api";
import { useSelector } from "react-redux";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";

function Details({ data, rows, paymentData, paymentRows, title, pathname }) {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const currentCompany = useSelector(
    (state) => state.accountData.currentCompany
  );

  return (
    <div className=" text-gray-800 flex flex-col mx-auto ">
      <Link
        to={pathname}
        className="bg-slate-500 text-white px-2 py-1 rounded my-5 ml-4 w-fit gap-2 flex items-center hover:bg-slate-700 hover:text-white"
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        Retour
      </Link>
      {data
        .filter((item) => item.uuid === uuid)
        .map((item, i) => {
          return (
            <div
              className=" rounded-2xl flex flex-col m-4 shadow-lg items-center pt-10 pb-5 bg-white"
              key={i}
            >
              <h2 className="text-center text-lg mb-5 font-semibold">
                {title}
              </h2>
              <div className="grid grid-cols-2 gap-6 border rounded-xl p-5">
                {rows.map(({ accessor, label, format, icon }) => {
                  const listItem = item[accessor] ? item[accessor] : "——";
                  return (
                    <>
                      <ul className="" key={label}>
                        <li className="flex flex-col" >
                          <div className="flex items-center border-b">
                            {icon ? (
                              <span className="pr-2 self-end">{icon}</span>
                            ) : null}

                            <span className=" border-b border-transparent font-semibold text-sm">
                              {label}
                            </span>
                          </div>

                          {accessor === "captureBtn" &&
                          item.is_capturable === true ? (
                            <span
                              key={accessor}
                              className=" rounded flex gap-1"
                            >
                              <button
                                onClick={() => {
                                  confirmAlert({
                                    customUI: ({ onClose }) => {
                                      return (
                                        <div className="bg-slate-700 p-10 rounded-md text-white flex flex-col gap-6">
                                          <h1 className="text-lg">
                                            Voulez-vous capturer le paiement?
                                          </h1>
                                          <div className="flex flex-row items-center justify-center gap-4 ">
                                            <button
                                              onClick={onClose}
                                              className="border-2 border-white rounded-md px-2 py-1 hover:bg-white hover:text-gray-700"
                                            >
                                              Non
                                            </button>
                                            <button
                                              onClick={() => {
                                                axios
                                                  .post(
                                                    `payment/${currentCompany}/${listItem.uuid}/capture/`
                                                  )
                                                  .then(() => {
                                                    return (item.status =
                                                      "payment_order.captured");
                                                  })
                                                  .catch((error) => {
                                                    if (error) {
                                                    }
                                                  });
                                                onClose();
                                              }}
                                              className="border-2 border-transparent text-white bg-[#2d916b] hover:bg-[#185e43] font-semibold rounded-md  px-2 py-1 cursor-pointer"
                                            >
                                              Oui
                                            </button>
                                          </div>
                                        </div>
                                      );
                                    },
                                  });
                                }}
                                className=" text-white bg-[#2d916b] hover:bg-[#185e43] rounded-lg text-sm px-2 py-1 cursor-pointer"
                              >
                                Capturer
                              </button>
                            </span>
                          ) : accessor === "status" &&
                            item.as_captured === true &&
                            item.status === "payment_order.successed" ? (
                            <span className="  text-[#2d916b] font-medium ">
                              Payé/Capturé
                            </span>
                          ) : accessor === "status" &&
                            item.status === "payment_order.successed" ? (
                            <span className="  text-[#2d916b] font-medium ">
                              Payé
                            </span>
                          ) : format === "date" ? (
                            <span className="">
                              {new Date(listItem)
                                .toLocaleDateString("fr")
                                .replace(/\//g, "-")}
                            </span>
                          ) : format === "currency" ? (
                            <span key={accessor} className="  ">
                              {`${listItem.toFixed(2)} €`}
                            </span>
                          ) : accessor === "payment_type" &&
                            item.payment_type === "payment" ? (
                            <span key={accessor} className="  ">
                              Paiement
                            </span>
                          ) : accessor === "payment_type" &&
                            item.payment_type === "fingerprint" ? (
                            <span key={accessor} className="  ">
                              Empreinte
                            </span>
                          ) : (
                            <span key={accessor} className=" text-md  ">
                              {listItem}
                            </span>
                          )}
                        </li>
                      </ul>
                    </>
                  );
                })}
              </div>

              {paymentData && paymentData.length > 1 ? (
                <>
                  <caption className="font-semibold pt-6">
                    Paiements associés:{" "}
                  </caption>
                  <table className="flex flex-col justify-between mt-4 bg-slate-50 border-slate-200 border rounded-lg shadow-sm">
                    <div className="flex flex-row">
                      <thead className=" pr-10">
                        <tr className="flex flex-col items-end ">
                          {paymentRows.map(({ label }) => {
                            return (
                              <th className="p-2" key={label}>
                                {label}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>

                      <tbody className="flex flex-row gap-3 mb-2">
                        {paymentData.map((item, i) => {
                          return (
                            <tr
                              className="w-fit flex flex-col items-center rounded"
                              key={i}
                            >
                              {paymentRows.map(({ accessor, format }) => {
                                const listItem = item[accessor]
                                  ? item[accessor]
                                  : "——";

                                if (accessor === "moreBtn") {
                                  return (
                                    <td className="p-2" key={accessor}>
                                      <Link
                                        to={`/dashboard/payments/${item.uuid}`}
                                        className="font-semibold text-violet-900 hover:underline flex items-center gap-2"
                                      >
                                        Détails
                                      </Link>
                                    </td>
                                  );
                                } else if (
                                  format === "date" &&
                                  item.payment_date !== null
                                ) {
                                  return (
                                    <td
                                      key={accessor}
                                      className=" rounded flex gap-1  p-2 "
                                    >
                                      {" "}
                                      {new Date(listItem)
                                        .toLocaleDateString("fr")
                                        .replace(/\//g, "-")}
                                    </td>
                                  );
                                } else if (
                                  item.payment_date === null &&
                                  accessor === "payment_date"
                                ) {
                                  return (
                                    <td
                                      key={accessor}
                                      className=" rounded flex gap-1  p-2 "
                                    >
                                      ----
                                    </td>
                                  );
                                } else if (format === "currency") {
                                  return (
                                    <td
                                      key={accessor}
                                      className=" rounded flex gap-1 p-2 "
                                    >
                                      {`${listItem.toFixed(2)} €`}
                                    </td>
                                  );
                                } else if (
                                  item.status === "payment_order.pending" &&
                                  accessor === "status"
                                ) {
                                  return (
                                    <td key={accessor} className="p-2">
                                      <span className="bg-orange-200 px-2 py-1 rounded-md">
                                        En attente
                                      </span>
                                    </td>
                                  );
                                } else if (
                                  item.status === "payment_order.authorized" &&
                                  accessor === "status"
                                ) {
                                  return (
                                    <td key={accessor} className="p-2">
                                      <span className="bg-blue-200 px-2 py-1 rounded-md">
                                        Autorisé
                                      </span>
                                    </td>
                                  );
                                } else if (
                                  item.status === "payment_order.successed" &&
                                  accessor === "status"
                                ) {
                                  return (
                                    <td key={accessor} className="p-2">
                                      <span className="bg-green-200 px-2 py-1 rounded-md">
                                        Payé
                                      </span>
                                    </td>
                                  );
                                } else if (
                                  item.status === "payment_order.refused" &&
                                  accessor === "status"
                                ) {
                                  return (
                                    <td key={accessor} className="p-2">
                                      <span className="bg-red-200 px-2 py-1 rounded-md">
                                        Refusé
                                      </span>
                                    </td>
                                  );
                                } else if (
                                  item.status === "not_initiated" &&
                                  accessor === "status"
                                ) {
                                  return (
                                    <td key={accessor} className="p-2">
                                      <span className="bg-yellow-100 px-2 py-1 rounded-md">
                                        Pas initialisé
                                      </span>
                                    </td>
                                  );
                                } else if (
                                  item.status === "payment_order.refunded" &&
                                  accessor === "status"
                                ) {
                                  return (
                                    <td key={accessor} className="p-2">
                                      <span className="bg-yellow-700 px-2 py-1 rounded-md">
                                        Remboursé
                                      </span>
                                    </td>
                                  );
                                } else {
                                  return (
                                    <td
                                      key={accessor}
                                      className=" rounded flex gap-1 p-2 border-b"
                                    >
                                      {listItem}
                                    </td>
                                  );
                                }
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </div>
                  </table>
                </>
              ) : null}
            </div>
          );
        })}
    </div>
  );
}

export default Details;
