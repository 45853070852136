import React, { useEffect, useState } from "react";

function FilterDropdown({ data, setSortedData }) {
  const [filter, setFilter] = useState("all");

  const selectedFilter = sessionStorage.getItem("selectedFilter");
  useEffect(() => {
    if (selectedFilter) {
      let arr = [];
      data.filter((booking) => {
        if (booking.pms_reservation_type === selectedFilter) {
          arr.push(booking);
        } else if (selectedFilter === "all") {
          return setSortedData(data);
        }
        setSortedData(arr);
      });
    } else {
      return setSortedData(data);
    }
  }, [selectedFilter, data, filter]);

  const handleFilterBtn = (e) => {
    sessionStorage.setItem("selectedFilter", e.target.value);
    setFilter(e.target.value);
    if (e.target.value === "all") {
      return setSortedData(data);
    }
  };

  return (
    <select
      className="px-2 mb-4 rounded-md cursor-pointer bg-white hover:border-violet-800 border-2 duration-300"
      onChange={handleFilterBtn}
      value={selectedFilter ? selectedFilter : filter}
    >
      <option value="all">Tout</option>
      <option value="restaurant">Restaurant</option>
      <option value="hotel">Hôtel</option>
    </select>
  );
}

export default FilterDropdown;
