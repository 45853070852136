import React from "react";
import classNames from "classnames";

function Button(props) {
  const { children, type, onClick, ...rest } = props;

  const buttonStyles = classNames('',
    type === "primary"
      ? "my-4 py-2 px-4 bg-violet-600 text-white font-semibold rounded-lg shadow-md hover:bg-violet-700 focus:outline-none focus:ring-2 cursor-pointer"
      : type === "details"
      ? "font-medium text-violet-900 hover:underline flex items-center gap-2"
      : type === "selectBtn"
      ? "hidden md:max-lg:flex hover:bg-slate-200 bg-slate-50 max-md:hover:text-gray-800 md:font-semibold rounded-lg px-[20px] py-3 border-transparent shadow-md  my-4 items-center justify-center cursor-pointer"
      : type === "captured"
      ? "pr-2 border-b text-center text-[#2d916b] font-medium max-sm:hidden"
      : type === "authorized"
      ? "bg-gray-500"
      : ""
  );

  return (
    <button type={type} className={buttonStyles} onClick={onClick} {...rest}>
      {children}
    </button>
  );
}

export default Button;
