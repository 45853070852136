import React from "react";
import { Link } from "react-router-dom";
import CompaniesSelect from "../CompaniesSelect/CompaniesSelect";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import { ReactComponent as LogoTxt } from "../../assets/mpay-txt.svg";
import { ReactComponent as Logo } from "../../assets/mpay-icon.svg";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { setMenuIsOpen } from "../../store/accountDataSlice";
import { useRef } from "react";
import { useEffect } from "react";

function Header() {
  const menuIsOpen = useSelector((state) => state.accountData.menuIsOpen);
  const dispatch = useDispatch();
  const dropRef = useRef(null);

  useEffect(() => {
    const closeDropdown = (e) => {
      if (!dropRef.current.contains(e.target)) {
        dispatch(setMenuIsOpen(false));
      }
    };

    document.addEventListener("click", closeDropdown);
    return () => document.removeEventListener("click", closeDropdown);
  });

  return (
    <div className="h-[7vh] bg-slate-100 drop-shadow-md flex gap-10 justify-between items-center md:hidden">
      <Link to="dashboard/home" className="justify-start hidden xl:flex">
        <LogoTxt className="h-12 md:h-16 min-w-[100px] lg:h-[8vh] cursor-pointer lg:ml-8 " />
      </Link>
      <Link to="dashboard/home" className="flex justify-start xl:hidden">
        <Logo className="h-8 md:h-10 lg:h-[6vh] cursor-pointer ml-5" />
      </Link>
      <div className="flex flex-row items-center md:gap-10 gap-4">
        <div className="">
          <CompaniesSelect />
        </div>
        <div className="flex flex-row items-center gap-5 lg:justify-end mr-7">
          <ProfileDropdown />
          <div className="block lg:hidden">
            <button
              onClick={() => dispatch(setMenuIsOpen(!menuIsOpen))}
              className="flex items-center text-black-500 hover:text-black-400"
              ref={dropRef}
            >
              <FontAwesomeIcon
                icon={faBars}
                className={`fill-current h-6 w-5 ${
                  menuIsOpen ? "hidden" : "block"
                }`}

              />
              <FontAwesomeIcon
                icon={faXmark}
                className={`fill-current h-6 w-5 ${
                  menuIsOpen ? "block" : "hidden"
                }`}

              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
