import React from "react";

const TableHead = ({ columns }) => {
  return (
    <thead className="border-gray-600 border-opacity-75 uppercase text-xs text-gray-700">
      <tr className="md:bg-gray-200  max-sm:border-b">
        {columns.map(({ label, accessor }) => {
          if (accessor === "first_name" || accessor === "last_name") {
            return (
              <th
                key={accessor}
                className="max-sm:p-3 pl-3 md:pl-2 text-left text-slate-700"
              >
                {label}
              </th>
            );
          } else {
            return (
              <th key={accessor} className="p-4 text-center max-sm:hidden">
                {label}
              </th>
            );
          }
        })}
      </tr>
    </thead>
  );
};

export default TableHead;
