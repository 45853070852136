import React from "react";
import { ReactComponent as LogoTxt } from "../../assets/mpay-txt.svg";
import { Link } from "react-router-dom";

function LandingPage() {
  return (
    <div className="flex flex-col items-center bg-[#fafafa] h-screen text-gray-700 md:gap-20">
      <LogoTxt className="w-[150px] md:self-start h-auto md:mb-10 md:ml-10 max-sm:mb-10" />
      <div className="flex flex-col  items-center">
        <span className="px-10 text-lg md:text-5xl lg:text-[48px] font-bold max-w-[1200px] text-center">
          Une vue globale sur vos réservations et vos paiements. Connectez-vous
          pour avoir accès à votre Dashboard.
        </span>
        <Link to="/login" className="mt-5">
          <button className="my-4 py-2 px-4 border-2  border-gray-500 hover:bg-violet-600 hover:text-white hover:border-transparent font-semibold rounded-lg shadow-md focus:outline-none cursor-pointer">
            Login
          </button>
        </Link>
      </div>
    </div>
  );
}

export default LandingPage;
