import React, { useState } from "react";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function Table({ tableData, columns, pagination }) {
  const pageNumber = sessionStorage.getItem("pageNumber");
  const searching = sessionStorage.getItem("searching");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  const location = useLocation();

  useEffect(() => {
    if (searching && searching.length > 0 || location.pathname.includes('home')) {
      // sessionStorage.setItem("pageNumber", 1);
      setCurrentPage(1);
    }
  }, [searching, location]);

  const paginate = ({ selected }) => {
    sessionStorage.setItem("pageNumber", selected + 1);
    setCurrentPage(selected + 1);
  };

  const pageCount = Math.ceil(tableData.length / itemsPerPage);

  return (
    <div>
      <table className="w-full text-base border-[1px] bg-white ">
        <TableHead columns={columns} />
        <TableBody columns={columns} tableData={currentItems} />
      </table>
      {pagination ? (
        <ReactPaginate
          forcePage={currentPage - 1}
          onPageChange={paginate}
          pageCount={pageCount}
          previousLabel={
            <FontAwesomeIcon
              icon={faAngleLeft}
              size="sm"
              style={{ color: "gray" }}
            />
          }
          nextLabel={
            <FontAwesomeIcon
              icon={faAngleRight}
              size="sm"
              style={{ color: "gray" }}
            />
          }
          pageLinkClassName={
            " px-2 py-1 hover:bg-slate-300 text-slate-500 font-semibold rounded"
          }
          previousLinkClassName={
            " rounded-tl-md rounded-bl-md px-2 py-1 hover:bg-slate-300 "
          }
          nextLinkClassName={
            "rounded-tr-md rounded-br-md px-2 py-1 hover:bg-slate-300 "
          }
          activeLinkClassName={"!bg-slate-300 rounded !text-white"}
          className="flex items-center justify-center my-5"
          renderOnZeroPageCount={null}
        />
      ) : null}
    </div>
  );
}

export default Table;
