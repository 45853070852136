import React, { lazy, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import UpdateProfileForm from "../components/UpdateProfileForm";
import { Suspense } from "react";
import Layout from "../pages/Layout/Layout";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import Bookings from "../pages/Bookings";
import PaymentOrders from "../pages/PaymentOrders";
import Help from "../pages/Help";
import { useDispatch, useSelector } from "react-redux";
import PaymentPage from "../pages/PaymentPage";
import LandingPage from "../pages/LandingPage";
import Spinner from "../components/Spinner";
import BookingDetails from "../pages/BookingDetails";
import PaymentOrderDetail from "../pages/PaymentOrderDetail";
import {
  setToken,
  setAccountInfo,
  setCurrentCompany,
  setFirstName,
  setLastName,
} from "../store/accountDataSlice";

function AppRoutes() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.accountData.token);

  useEffect(() => {
    let accountInfo = sessionStorage.getItem("accountInfo");
    let token = JSON.parse(sessionStorage.getItem("token"));
    let currentCompany = sessionStorage.getItem("companyCode");

    dispatch(setAccountInfo(JSON.parse(accountInfo)));
    dispatch(setToken(token));
    dispatch(setCurrentCompany(currentCompany));

    if (accountInfo && currentCompany) {
      dispatch(setCurrentCompany(currentCompany));
      dispatch(setFirstName(accountInfo.first_name));
      dispatch(setLastName(accountInfo.last_name));
    }
  }, []);

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        {token ? (
          <>
            <Route path="/" element={<Layout />}>
              <Route path="dashboard/home" element={<Dashboard />} />
              <Route path="dashboard/bookings" element={<Bookings />} />

              <Route path="dashboard/payments" element={<PaymentOrders />} />
              <Route
                path="dashboard/bookings/:uuid"
                element={<BookingDetails />}
              />
              <Route
                path="dashboard/payments/:uuid"
                element={<PaymentOrderDetail />}
              />
              <Route path="dashboard/help" element={<Help />} />
              <Route path="dashboard/account" element={<UpdateProfileForm />} />
            </Route>
            <Route path="/login" element={<Login />} />
            {/* <Route
              path="/"
              element={<Navigate to="dashboard/home" replace />}
            /> */}
          </>
        ) : (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<LandingPage />} />
          </>
        )}
        <Route path=":uuid" element={<PaymentPage />} />
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
