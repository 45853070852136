import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faMoneyBills, faList } from "@fortawesome/free-solid-svg-icons";

function DashboardCard({ paymentsData, bookingsData }) {
  return (
    <div className="border-transparent shadow-md  rounded-lg flex flex-col gap-6 bg-[#232739] px-[17px] py-[16px] ">
      <div className="flex items-center md:justify-between justify-center sm:max-md:justify-around">
        <div className="flex gap-8 max-sm:hidden">
          <div className="flex items-center text-white gap-2">
            <span className="border-[1px] border-[#4e17bb] bg-[#ddd9e6] rounded-md h-[40px] w-[40px] flex items-center justify-center">
              <FontAwesomeIcon
                icon={faMoneyBills}
                size="lg"
                style={{ color: "#4e17bb" }}
              />
            </span>
            <div className="flex flex-col">
              <span className="text-xs opacity-80">Total Paiements</span>
              <span className="text-left text-lg font-semibold">
                {paymentsData?.length}
              </span>
            </div>
          </div>
          <div className="flex items-center text-white gap-2">
            <span className="border-[1px] border-[#4e17bb] bg-[#ddd9e6]  rounded-md h-[40px] w-[40px] flex items-center justify-center">
              <FontAwesomeIcon
                icon={faBed}
                size="lg"
                style={{ color: "#4e17bb" }}
              />
            </span>
            <div className="flex flex-col">
              <span className="text-xs opacity-80">Total Réservations</span>
              <span className="text-left text-lg font-semibold">
                {bookingsData?.length}
              </span>
            </div>
          </div>
        </div>

        <div className="flex gap-2 sm:max-md:flex-col-reverse sm:max-md:justify-center sm:max-md:items-center">
          <Link to="/dashboard/bookings">
            <div className="inline-flex font-semibold items-center px-[24px] gap-2 text-sm text-center bg-slate-200 text-[#4e17bb] duration-300 rounded-lg hover:bg-[#4e17bb]  hover:text-white h-[40px] border-2 border-[#4e17bb] ">
              <FontAwesomeIcon icon={faList} />
              Réservations
            </div>
          </Link>
          <Link to="/dashboard/payments">
            <div className="inline-flex items-center font-semibold px-[24px] gap-2 text-sm text-center bg-slate-200 text-[#4e17bb]  duration-300 rounded-lg hover:bg-[#4e17bb]  hover:text-white  h-[40px] border-[1.5px] border-[#4e17bb]">
              <FontAwesomeIcon icon={faList} />
              Paiements
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default DashboardCard;
