import React, { useState, useEffect } from "react";
import axios from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCompany } from "../../store/accountDataSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";

function CompaniesSelect() {
  const [options, setOptions] = useState([]);
  const currentCompany = useSelector(
    (state) => state.accountData.currentCompany
  );
  const dispatch = useDispatch();

  useEffect(() => {
    let companies = [];
    axios.get(`/account/my/`).then((data) => {
      data.data.company_ids.map((item) => {
        return companies.push({ value: item.code, label: item.name });
      });
      setOptions(companies);
    });
  }, []);

  const handleChangeSelect = (e) => {
    dispatch(setCurrentCompany(e.target.value));
    sessionStorage.setItem("currentCompany", e.target.value);
  };

  return (
    <div className="flex flex-row gap-2 border shadow-sm rounded-lg p-1 justify-center bg-white lg:px-5 lg:py-1 lg:gap-4">
      <span className="text-md md:text-xl text-gray-800 md:max-lg:hidden">
        <FontAwesomeIcon icon={faBuilding} className="mb-[-1px]" />
      </span>
      <select
        className=" bg-white text-gray-800 font-bold cursor-pointer text-xs md:text-base hover:text-violet-600 outline-none duration-300 "
        onChange={handleChangeSelect}
        value={currentCompany}
        defaultValue={
          sessionStorage.getItem("currentCompany")
            ? sessionStorage.getItem("currentCompany")
            : currentCompany
        }
      >
        {options.map((option, i) => {
          return (
            <option value={option.value} key={i}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default CompaniesSelect;
