import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Details from "../../components/Details/Details";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faCalendarXmark,
  faChildren,
  faHashtag,
  faPhone,
  faEnvelope,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";

function BookingDetails() {
  const [guestPayments, setGuestPayments] = useState([]);
  let paymentsData = JSON.parse(sessionStorage.getItem("paymentsData"));
  let bookingsData = JSON.parse(sessionStorage.getItem("bookingsData"));
  const { uuid } = useParams();

  useEffect(() => {
    const arr = [];
    paymentsData.map((payment) => {
      if (payment.reservation_id === uuid) {
        arr.push(payment);
      }
      setGuestPayments(arr);
    });
  }, []);

  const rows = [
    { label: "Nom", accessor: "last_name" },
    { label: "Prénom", accessor: "first_name" },
    {
      label: "Check-In",
      accessor: "checkin",
      icon: <FontAwesomeIcon icon={faCalendarCheck} />,
      format: "date",
    },
    {
      label: "Check-Out",
      accessor: "checkout",
      icon: <FontAwesomeIcon icon={faCalendarXmark} />,
      format: "date",
    },
    {
      label: "Adulte(s)",
      accessor: "adults",
      icon: <FontAwesomeIcon icon={faPeopleGroup} />,
    },
    {
      label: "Enfant(s)",
      accessor: "children",
      icon: <FontAwesomeIcon icon={faChildren} />,
    },
    {
      label: "Adresse mail",
      accessor: "email",
      icon: <FontAwesomeIcon icon={faEnvelope} />,
    },
    {
      label: "Téléphone",
      accessor: "phone",
      icon: <FontAwesomeIcon icon={faPhone} />,
    },
    {
      label: "Référence",
      accessor: "pms_reservation_id",
      icon: <FontAwesomeIcon icon={faHashtag} />,
    },
  ];

  const paymentRows = [
    { label: "Créé le:", accessor: "created_at", format: "date" },
    { label: "Montant:", accessor: "amount", format: "currency" },
    { label: "Status:", accessor: "status" },
    { label: "", accessor: "moreBtn" },
  ];

  return (
    <Details
      data={bookingsData}
      rows={rows}
      paymentData={guestPayments}
      paymentRows={paymentRows}
      title={"Détails de la réservation"}
      pathname={"/dashboard/bookings"}
    />
  );
}

export default BookingDetails;
