import "./App.css";
import { AuthProvider } from "./context/AuthContext";
import AppRoutes from "./routes/AppRoutes";
import { store } from "./store";
import { Provider } from "react-redux";

function App() {
  let token = sessionStorage.getItem("token");
  token = JSON.parse(token);

  return (
    <div>
      <AuthProvider userData={token}>
        <Provider store={store}>
          <AppRoutes />
        </Provider>
      </AuthProvider>
    </div>
  );
}

export default App;
