import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as LogoTxt } from "../../assets/mpay-txt.svg";
import { ReactComponent as Logo } from "../../assets/mpay-icon.svg";
import ProfileDropdown from "../ProfileDropdown";
import CompaniesSelect from "../CompaniesSelect";
import NavBarItem from "./NavBarItem";
import Button from "../Button";
import useOutsideClick from "../../hooks/useOutsideClick";
import "react-tooltip/dist/react-tooltip.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGauge,
  faBed,
  faCircleInfo,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";

function NavBar() {
  const navItems = [
    {
      path: "/dashboard/home",
      name: "Dashboard",
      icon: <FontAwesomeIcon icon={faGauge} size={"lg"} />,
    },
    {
      path: "/dashboard/bookings",
      name: "Réservations",
      icon: <FontAwesomeIcon icon={faBed} size={"sm"} />,
    },
    {
      path: "/dashboard/payments",
      name: "Paiements",
      icon: <FontAwesomeIcon icon={faMoneyBill} size={"lg"} />,
    },
    {
      path: "/dashboard/help",
      name: "Aide",
      icon: <FontAwesomeIcon icon={faCircleInfo} size={"lg"} />,
    },
  ];

  const menuIsOpen = useSelector((state) => state.accountData.menuIsOpen);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useOutsideClick(false);

  const toggleDropdown = () => {
    setIsComponentVisible((prevState) => !prevState);
  };

  return (
    <div
      className={`bg-[#23273a] rounded-md  md:bg-white text-[#323338] shadow-lg flex flex-col items-start absolute right-0 z-20 px-3 md:border-r md:border-gray-200 md:h-screen md:relative lg:px-[20px] md:items-center lg:items-start max-md:py-2 max-md:text-white ${
        !menuIsOpen ? "max-md:hidden" : ""
      }`}
    >
      <Tooltip id="my-tooltip" className="hidden md:max-lg:flex " />

      {/*  logos */}
      <Link to="dashboard/home" className="justify-start hidden lg:flex">
        <LogoTxt className="h-12 md:h-16 min-w-[100px] lg:h-[75px] cursor-pointer " />
      </Link>
      <Link to="dashboard/home" className="justify-start hidden md:max-lg:flex">
        <Logo className="h-14 min-w-[100px] cursor-pointer mt-[12px] mb-[4px]" />
      </Link>

      {/*  company change select */}
      <Button
        type={"selectBtn"}
        onClick={toggleDropdown}
        data-tooltip-id="my-tooltip"
        data-tooltip-content="Changer la société"
      >
        <FontAwesomeIcon icon={faBuilding} size="xl" />
      </Button>

      <div
        ref={ref}
        className={`max-lg:hidden  md:w-full mt-[18px] ${
          isComponentVisible
            ? "md:max-lg:flex md:max-lg:absolute md:max-lg:origin-top-right md:max-lg:left-20 md:max-lg:top-24 md:max-lg:z-20"
            : ""
        }`}
      >
        <CompaniesSelect />
        <div className="md:border-b md:mt-5 md:mb-[2px]"></div>
      </div>

      {/*  navlinks */}
      <div className="flex flex-col gap-[16px] items-center md:mt-[16px]">
        {navItems.map((item, i) => (
          <NavBarItem item={item} key={i} />
        ))}
      </div>

      {/*  account btn */}
      <div className="max-md:hidden md:mt-[40vh]">
        <ProfileDropdown />
      </div>
    </div>
  );
}

export default NavBar;
