import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBookingsData, setPaymentsData } from "../../store/accountDataSlice";
import axios from "../../services/api";
import DashboardCard from "../../components/DashboardCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import Table from "../../components/Table/Table";
import { useNavigate } from "react-router-dom";
function Dashboard() {
  const [bookings, setBookings] = useState([]);
  const [payments, setPayments] = useState([]);

  const currentCompany = useSelector(
    (state) => state.accountData.currentCompany
  );


  // const currentCompany = sessionStorage.getItem('companyCode')
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const columns = [
    { label: "Nom", accessor: "last_name" },
    { label: "Prénom", accessor: "first_name" },
    { label: "Type", accessor: "payment_type" },
    { label: "Créé le", accessor: "created_at", format: "date" },
    { label: "Montant", accessor: "amount", format: "currency" },
    { label: "", accessor: "dashboardBtn" },
  ];

  useEffect(() => {
    let arr = [];
    if (currentCompany) {
      axios
        .get(`payment/${currentCompany}/`)
        .then((data) => {
          sessionStorage.setItem("paymentsData", JSON.stringify(data.data));
          dispatch(setPaymentsData(data.data));
          data?.data.map((item) => {
            return arr.push(item);
          });
          return setPayments(arr);
        })
        .catch((error) => {
          if (error) {
            sessionStorage.clear();
            navigate("/login");
          }
        });
    }
  }, [currentCompany]);

  useEffect(() => {
    let arr = [];
    if (currentCompany) {
      axios.get(`reservation/${currentCompany}/`).then((data) => {
        sessionStorage.setItem("bookingsData", JSON.stringify(data.data));
        dispatch(setBookingsData(data.data));
        data.data.map((item) => {
          return arr.push(item);
        });
        return setBookings(arr);
      });
    }
  }, [currentCompany]);

  let completeData = [];
  payments.map((item) => {
    bookings.find((p) => {
      if (p.uuid === item.reservation_id) {
        completeData.push({
          ...item,
          first_name: p.first_name,
          last_name: p.last_name,
        });
      } else {
      }
    });
  });

  return (
    <div className=" bg-slate-50 flex flex-col w-full min-h-screen px-[20px]">
      <h1 className="text-center md:text-[20px] md:text-left text-gray-700 text-lg md:text-lg font-semibold md:pt-[28px] md:pb-[21px] max-md:p-4">
        Dashboard
      </h1>

      <div className=" flex flex-col gap-[15px] ">
        <DashboardCard
          title={"Paiements"}
          bookingsData={bookings}
          paymentsData={payments}
          header={"Tous les paiements"}
          pathname={"/dashboard/payments/"}
          icon={
            <FontAwesomeIcon
              icon={faFileInvoiceDollar}
              size="xl"
              style={{ color: "#25124E" }}
            />
          }
        />
        <Table tableData={completeData.slice(0, 15)} columns={columns} />
      </div>
    </div>
  );
}

export default Dashboard;
