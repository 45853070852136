import React from "react";
import { NavLink } from "react-router-dom";
import { Tooltip } from "react-tooltip";

function NavBarItem({ item }) {
  return (
    <>
      <NavLink
        data-tooltip-id="my-tooltip"
        data-tooltip-content={item.name}
        to={item.path}
        className={({ isActive }) =>
          isActive
            ? " text-[#4e17bb] md:font-semibold bg-gray-200 rounded-lg px-3 py-2 w-full flex md:items-center md:justify-center lg:justify-start gap-2 "
            : "hover:bg-slate-200 duration-300 max-md:hover:text-gray-800 md:font-semibold rounded-lg px-3 py-2 w-full flex md:items-center md:justify-center lg:justify-start gap-2 "
        }
      >
        <span className="md:max-lg:text-2xl">{item.icon}</span>
        <span className="md:max-lg:hidden">{item.name}</span>
      </NavLink>
      <Tooltip id="my-tooltip" className="hidden md:max-lg:flex " />
    </>
  );
}

export default NavBarItem;
