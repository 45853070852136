import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as LogoTxt } from "../../assets/mpay-txt.svg";
import { useNavigate } from "react-router-dom";
import axios from "../../services/api";
import { useDispatch } from "react-redux";
import {
  setAccountInfo,
  setToken,
  setFirstName,
  setCurrentCompany,
  setLastName,
  setIsLoggedIn,
} from "../../store/accountDataSlice";
import Button from "../../components/Button/Button";

function Login() {
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const userRef = useRef();
  const errRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    setErrMsg("");
  }, [email, pwd]);

  const handleLogin = (e) => {
    e.preventDefault();

    try {
      let response = axios
        .post(`token/`, JSON.stringify({ email: email, password: pwd }), {
          headers: {
            "Content-Type": "application/json",
          },
        })

        .then(function (response) {
          sessionStorage.setItem("access_token", response.data.access);
          sessionStorage.setItem("refresh_token", response.data.refresh);
          dispatch(setIsLoggedIn(true));
          getDashboardData();

          return setProfile(response);
        })
        .catch((error) => {
          console.log(error);
          setErrMsg("Email ou mot de passe incorrect");
        });
      if (response.data && response.data.success === false) {
        return setErrMsg("error");
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        return setErrMsg("error");
      }
      return setErrMsg("error");
    }
  };

  const setProfile = (response) => {
    let token = { ...response.data };
    token = JSON.stringify(token);
    dispatch(setToken(token));
    sessionStorage.setItem("token", token);
    if (token) {
      navigate("/dashboard/home");
    } else {
      navigate("../");
    }
  };

  const getDashboardData = () => {
    let companiesInfoList = [];
    let defaultCompanyId = "";
    axios.get(`account/my/`).then((data) => {
      sessionStorage.setItem("accountInfo", JSON.stringify(data.data));
      dispatch(setAccountInfo(data.data));
      defaultCompanyId = data.data.default_company_id;
      companiesInfoList = data.data.company_ids;
      let companyCode = companiesInfoList.find(
        (company) => company.pk === defaultCompanyId
      ).code;
      sessionStorage.setItem("companyCode", companyCode);
      dispatch(setFirstName(data.data.first_name));
      dispatch(setLastName(data.data.last_name));
      dispatch(setCurrentCompany(companyCode));
    });
  };

  return (
    <div className="flex flex-col items-center bg-[#fafafa] px-10 h-screen">
      <LogoTxt className="h-[120px] w-[200px] lg:w-[250px] md:w-[250px] mt-16" />

      <div className="container mx-auto px-2 sm:px-6 md:px-8 lg:px-10 rounded-md max-w-md py-10 my-15 flex flex-col items-center shadow bg-white">
        <form action="/account" method="post" >
          <div>
            <label className="text-sm" htmlFor="email">
              E-mail
            </label>
            <input
              className="sm:w-96 w-full mt-1 block px-3 py-2 mb-5 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
            focus:outline-none focus:border-violet-500 focus:ring-1 focus:ring-violet-500
            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
              type="email"
              name="email"
              id="email"
              placeholder="email@example.com"
              required
              value={email}
              ref={userRef}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label className="text-sm" htmlFor="password">
              Mot de passe
            </label>
            <input
              className="mt-1 block w-full px-3 py-2 mb-7 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
            focus:outline-none focus:border-violet-500 focus:ring-1 focus:ring-violet-500
            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
              type="password"
              name="password"
              id="password"
              placeholder="Mot de passe"
              required
              value={pwd}
              onChange={(e) => setPwd(e.target.value)}
            />
          </div>
          <div>
            <div className="flex items-center mt-2">
              <input
                id="remember"
                type="checkbox"
                className="mr-2 w-3 h-3 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                require=""
              />
              <label
                htmlFor="remember"
                className="text-sm text-gray-500 dark:text-gray-300"
              >
                Se souvenir du mot de passe
              </label>
            </div>
          </div>
          <div className="flex flex-col">
            {/* <input
              value="Se connecter"
              type="submit"
              className="my-4 py-2 px-4 bg-violet-600 text-white font-semibold rounded-lg shadow-md hover:bg-violet-700 focus:outline-none focus:ring-2 cursor-pointer"
            /> */}
             <Button type={'primary'} onClick={handleLogin}>Se connecter</Button>
            <p
              ref={errRef}
              className={
                errMsg ? "text-red-500 text-sm text-center font-semibold" : null
              }
              aria-live="assertive"
            >
              {errMsg}
            </p>


            <button className="mt-7 hover:text-violet-600">
              Mot de passe oublié
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
