import axios from "axios";
import TokenService from "./token.service";
export const BASE_URL = "%BASE_URL%";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = TokenService.getSessionAccessToken();
    if (token) {
      config.headers["Authorization"] = `Token ` + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        // sessionStorage.clear();
        // return <Navigate to='/login' replace={true}/>
        try {
          const rs = await TokenService.refreshToken();
          const { accessToken } = rs.data;
          sessionStorage.setItem("access_token", accessToken);
          axiosInstance.defaults.headers.common["Authorization"] =
            `Token` + accessToken;

          return axiosInstance(originalConfig);
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }

          return Promise.reject(_error);
        }
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }

    return Promise.reject(err);
  }
);

export default axiosInstance;
