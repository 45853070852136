import axiosInstance from "./api";

  const getSessionAccessToken = () => {
    const accessToken = sessionStorage.getItem("access_token");
    return accessToken;
  };

  const getSessionRefreshToken = () => {
    const refreshToken = sessionStorage.getItem("refresh_token");
    return refreshToken;
  };

  const refreshToken = () => {
    return axiosInstance.post("/token/refresh/", {
      refresh: getSessionRefreshToken(),
    });
  };



export default {getSessionAccessToken, getSessionRefreshToken, refreshToken};
